import { __decorate } from "tslib";
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { GLOBAL_DESCRIBE } from '@/store/templates/getters';
let DatasetsObjectsList = class DatasetsObjectsList extends Vue {
    constructor() {
        super(...arguments);
        this.globalDescribe = this.$store.getters[GLOBAL_DESCRIBE];
        this.tableFields = [
            { key: 'add', label: '' },
            { key: 'label', label: 'Label', thStyle: { width: '45%' } },
            { key: 'object', label: 'Object', thStyle: { width: '45%' } },
        ];
    }
    get items() {
        return this.objects.map(object => ({
            object,
            label: this.globalDescribe[object]?.label || object,
        }));
    }
    onDatasetSelected(row) {
        return row.object;
    }
};
__decorate([
    Prop()
], DatasetsObjectsList.prototype, "objects", void 0);
__decorate([
    Emit()
], DatasetsObjectsList.prototype, "onDatasetSelected", null);
DatasetsObjectsList = __decorate([
    Component({
        components: {},
    })
], DatasetsObjectsList);
export default DatasetsObjectsList;
