export default class TemplateDatasetsSequence {
    static create(steps) {
        let sourceSteps = steps;
        const objectList = sourceSteps.map(x => x.objectApiName);
        const targetSteps = [];
        while (sourceSteps.length) {
            const countSourceSteps = sourceSteps.length;
            TemplateDatasetsSequence.sortSteps(sourceSteps, targetSteps);
            sourceSteps = sourceSteps.filter(el => el);
            if (countSourceSteps === sourceSteps.length) {
                const { indexOfLow } = TemplateDatasetsSequence.minPriority(sourceSteps, targetSteps, objectList);
                targetSteps.push(sourceSteps[indexOfLow]);
                sourceSteps.splice(indexOfLow, 1);
            }
        }
        targetSteps.forEach(step => step.updateReferenceFields(targetSteps));
        return targetSteps;
    }
    static minPriority(sourceSteps, targetSteps, objectList) {
        const priority = sourceSteps.map(step => {
            let priorityRef = 0;
            priorityRef +=
                TemplateDatasetsSequence.fieldsNotRefToTargetSteps(step, targetSteps, false, objectList) *
                    TemplateDatasetsSequence.PRIORITY_OPTIONAL_FIELD;
            priorityRef +=
                TemplateDatasetsSequence.fieldsNotRefToTargetSteps(step, targetSteps, true, objectList) *
                    TemplateDatasetsSequence.PRIORITY_REQUIRED_FIELD;
            return priorityRef;
        });
        let valueLow = priority[0];
        let indexOfLow = 0;
        for (let i = 1; i < priority.length; i += 1) {
            if (valueLow > priority[i]) {
                valueLow = priority[i];
                indexOfLow = i;
            }
        }
        return { valueLow, indexOfLow };
    }
    static fieldsNotRefToTargetSteps(sourceStep, targetSteps, isRequiredRef, objectList) {
        const referenceFields = isRequiredRef ? sourceStep.requiredRelationships : sourceStep.optionalRelationships;
        let numberOfField = 0;
        for (const fieldName of [...Object.keys(referenceFields)]) {
            for (const reference of referenceFields[fieldName]) {
                if (objectList.includes(reference)) {
                    const referenceTo = targetSteps.find(x => x.objectApiName === reference);
                    if (!referenceTo)
                        numberOfField += 1;
                }
            }
        }
        return numberOfField;
    }
    static sortSteps(sourceSteps, targetSteps) {
        const count = sourceSteps.length;
        for (let i = 0; i < count; i += 1)
            TemplateDatasetsSequence.stepsRefToSortedSteps(sourceSteps, targetSteps);
    }
    static stepsRefToSortedSteps(sourceSteps, targetSteps) {
        let index = 0;
        for (const sourceStep of sourceSteps) {
            if (sourceStep &&
                TemplateDatasetsSequence.refToTargetStepsOrWithoutRef(sourceStep, targetSteps, true) &&
                TemplateDatasetsSequence.refToTargetStepsOrWithoutRef(sourceStep, targetSteps, false)) {
                targetSteps.push(sourceStep);
                delete sourceSteps[index];
            }
            index += 1;
        }
    }
    static refToTargetStepsOrWithoutRef(sourceStep, targetSteps, isRequiredRef) {
        const referenceFields = isRequiredRef ? sourceStep.requiredRelationships : sourceStep.optionalRelationships;
        for (const fieldName of [...Object.keys(referenceFields)]) {
            for (const reference of referenceFields[fieldName]) {
                const referenceTo = targetSteps.find(x => x.objectApiName === reference);
                if (!referenceTo)
                    return false;
            }
        }
        return true;
    }
}
TemplateDatasetsSequence.PRIORITY_REQUIRED_FIELD = 100;
TemplateDatasetsSequence.PRIORITY_OPTIONAL_FIELD = 1;
