import { __decorate } from "tslib";
import { Vue, Component, Model } from 'vue-property-decorator';
import Expandable from '@/components/shared/Expandable.vue';
import DatasetsObjectsList from '@/components/templates/DatasetsObjectsList.vue';
import DatasetsOverlaySteps from '@/components/templates/DatasetsOverlaySteps.vue';
import { TEMPLATE_DATASETS } from '@/store/templates/getters';
import TemplateDataset from '@/classes/template-dataset';
import TemplateDatasetsSequence from '@/classes/template-datasets-sequence';
import { ADD_DATASET_TO_TEMPLATE, DELETE_TEMPLATE_DATASET, UPDATE_TEMPLATE_DATASETS, } from '@/store/templates/mutations';
const DATASETS_LIST_CHANGED = 'DATASETS_LIST_CHANGED';
let DatasetsEditor = class DatasetsEditor extends Vue {
    constructor() {
        super(...arguments);
        this.tableFields = [
            { key: 'index', label: '#', thStyle: { width: '15%' } },
            { key: 'name', label: 'Data Set Name', thStyle: { width: '35%' } },
            { key: 'objectApiName', label: 'Object', thStyle: { width: '35%' } },
            { key: 'actions', label: '', thStyle: { width: '15%' } },
        ];
        this.selectedDataset = null;
        this.selectedTab = '';
        this.text = '1231';
    }
    get templateDatasets() {
        return this.$store.getters[TEMPLATE_DATASETS];
    }
    get datasets() {
        const sequence = TemplateDatasetsSequence.create(this.templateDatasets);
        TemplateDataset.SequenceDatasets = sequence;
        this.$emit(DATASETS_LIST_CHANGED, sequence);
        return sequence;
    }
    openTab(name) {
        this.selectedTab = this.selectedTab === name ? '' : name;
    }
    setTableRef(ref) {
        this.tableRef = ref;
    }
    addDataset(name) {
        this.$store.commit(ADD_DATASET_TO_TEMPLATE, {
            id: 0,
            objectApiName: name,
            name,
        });
        this.$store.commit(UPDATE_TEMPLATE_DATASETS);
    }
    selectDataset(item) {
        this.selectedDataset = item;
        this.selectedTab = '';
    }
    deleteDataset(item) {
        if (this.selectedDataset && this.selectedDataset === item) {
            this.selectedDataset = null;
        }
        this.$store.commit(DELETE_TEMPLATE_DATASET, item);
    }
    updateTableSelection(item) {
        if (!item && this.selectedDataset) {
            const selectedDatasetFromList = this.datasetsList.find(dataset => dataset.objectApiName === this.selectedDataset?.objectApiName);
            if (selectedDatasetFromList) {
                this.tableRef.selectRow(this.datasetsList.indexOf(selectedDatasetFromList));
            }
        }
    }
};
__decorate([
    Model(DATASETS_LIST_CHANGED)
], DatasetsEditor.prototype, "datasetsList", void 0);
DatasetsEditor = __decorate([
    Component({
        components: {
            Expandable,
            DatasetsObjectsList,
            DatasetsOverlaySteps,
        },
    })
], DatasetsEditor);
export default DatasetsEditor;
