var DatasetsModal_1;
import { __decorate } from "tslib";
import { Vue, Component, Watch, Inject, Emit } from 'vue-property-decorator';
import { SELECTED_DATASETS } from '@/store/templates/getters';
import { ADD_DATASET_TO_TEMPLATE, REMOVE_DATASET_FROM_TEMPLATE } from '@/store/templates/mutations';
import TemplatesApi from '@/services/api/templates.api';
let DatasetsModal = DatasetsModal_1 = class DatasetsModal extends Vue {
    constructor() {
        super(...arguments);
        this.modalId = DatasetsModal_1.name;
        this.tableFields = [
            { key: 'index', label: '', thStyle: { width: '10%', maxWidth: '5%' } },
            { key: 'name', label: 'Name', thStyle: { width: '30%', maxWidth: '30%' } },
            { key: 'objectApiName', label: 'Object', thStyle: { width: '30%', maxWidth: '30%' } },
            { key: 'description', label: 'Description', thStyle: { width: '30%', maxWidth: '30%' } },
        ];
        this.pagination = {
            page: 1,
            total: 0,
            limit: 25,
        };
        this.search = '';
        this.datasetsRecords = [];
        this.loading = false;
    }
    get datasets() {
        const { selectedIds, selectedObjects } = this;
        const selectedObjectNames = this.datasetsRecords
            .filter(dataset => dataset.selected)
            .map(dataset => dataset.objectApiName);
        selectedObjectNames.push(...selectedObjects);
        return this.datasetsRecords.map(dataset => ({
            ...dataset,
            disabled: !selectedIds.includes(dataset.id) && selectedObjectNames.includes(dataset.objectApiName),
            selected: selectedIds.includes(dataset.id),
        }));
    }
    get selectedIds() {
        return this.$store.getters[SELECTED_DATASETS].map(item => item.id);
    }
    get selectedObjects() {
        return this.$store.getters[SELECTED_DATASETS].map(item => item.objectApiName);
    }
    onFetchOptionsUpdated() {
        this.$nextTick(() => this.fetchDatasets());
    }
    handleModalClose() {
        this.$nextTick(() => this.$emit('close-modal'));
    }
    onDatasetsAdded() {
        this.hideModal();
    }
    mounted() {
        this.$bvModal.show(this.modalId);
        this.fetchDatasets();
    }
    hideModal() {
        this.$bvModal.hide(this.modalId);
    }
    toggleItem(itemId, value) {
        const item = this.datasetsRecords.find(dataset => dataset.id === itemId);
        if (item) {
            item.selected = value;
            if (value) {
                this.$store.commit(ADD_DATASET_TO_TEMPLATE, item);
            }
            else {
                this.$store.commit(REMOVE_DATASET_FROM_TEMPLATE, { id: item.id });
            }
        }
    }
    async fetchDatasets() {
        this.loading = true;
        this.datasetsRecords = [];
        try {
            const { page, limit } = this.pagination;
            const { dataSets, total } = await TemplatesApi.findDataSets({
                organizationId: +this.organizationId,
                page,
                limit,
                search: this.search,
            });
            this.pagination.total = total;
            this.datasetsRecords = dataSets;
        }
        catch (error) {
            this.$toast.error(error.message || error);
        }
        this.loading = false;
    }
};
__decorate([
    Inject()
], DatasetsModal.prototype, "organizationId", void 0);
__decorate([
    Watch('search'),
    Watch('pagination.page', { deep: true })
], DatasetsModal.prototype, "onFetchOptionsUpdated", null);
__decorate([
    Emit()
], DatasetsModal.prototype, "onDatasetsAdded", null);
DatasetsModal = DatasetsModal_1 = __decorate([
    Component({})
], DatasetsModal);
export default DatasetsModal;
