import { __decorate } from "tslib";
import { Component, Inject, Vue } from 'vue-property-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { Validate } from 'vuelidate-property-decorators';
import DatasetsEditor from '@/components/templates/DatasetsEditor.vue';
import DatasetsModal from '@/components/templates/DatasetsModal.vue';
import { RESET_TEMPLATE_STATE, SET_TEMPLATE_DATASETS, SET_TEMPLATE_GLOBAL_DESCRIBE, UPDATE_TEMPLATE_DATASETS, } from '@/store/templates/mutations';
import TemplatesApi from '@/services/api/templates.api';
import TemplateDataset from '@/classes/template-dataset';
const TEXT_FIELD_MAX_LENGTH = 255;
let CreateTemplatePage = class CreateTemplatePage extends Vue {
    constructor() {
        super(...arguments);
        this.DESCRIPTION_MAX_LENGTH = TEXT_FIELD_MAX_LENGTH;
        this.templateId = this.$route.params.templateId;
        this.templateName = '';
        this.templateDescription = '';
        this.showDatasetsModal = false;
        this.loading = false;
        this.datasets = [];
    }
    get mode() {
        return this.templateId === 'new' ? 'create' : 'edit';
    }
    created() {
        this.$store.commit(RESET_TEMPLATE_STATE);
    }
    mounted() {
        this.fetchData();
    }
    async submit() {
        try {
            this.$v.$touch();
            if (this.$v.$error) {
                return;
            }
            this.loading = true;
            const payload = {
                organizationId: +this.organizationId,
                name: this.templateName,
                description: this.templateDescription,
                dataSets: this.datasets.map(dataset => ({
                    id: dataset.id,
                    organizationId: +this.organizationId,
                    fields: dataset.fields.join(),
                    objectApiName: dataset.objectApiName,
                    queryWhereClause: dataset.queryWhereClause || '',
                    name: dataset.name,
                    overlaySteps: dataset.prepareOverlaySteps(),
                })),
            };
            if (this.mode === 'create') {
                await TemplatesApi.create(payload);
                this.$toast.success('Template successfully created');
            }
            else {
                await TemplatesApi.update(+this.templateId, payload);
                this.$toast.success('Template successfully updated');
            }
            await this.$router.push({ name: 'org.templates2.index' });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    updateDatasets() {
        this.$store.commit(UPDATE_TEMPLATE_DATASETS);
    }
    async fetchData() {
        this.loading = true;
        try {
            await this.fetchGlobalDescribe();
            await this.fetchTemplate();
        }
        catch (error) {
            this.$toast.error(error.message);
            await this.$router.push({
                name: 'org.templates2.index',
            });
        }
        this.loading = false;
    }
    async fetchGlobalDescribe() {
        const globalDescribe = await TemplatesApi.objectsDescribe(+this.organizationId);
        TemplateDataset.Describe = globalDescribe;
        this.$store.commit(SET_TEMPLATE_GLOBAL_DESCRIBE, globalDescribe);
    }
    async fetchTemplate() {
        if (this.mode !== 'create') {
            const template = await TemplatesApi.findById(+this.templateId);
            const dataSets = await TemplatesApi.findTemplateDataSets(+this.templateId);
            this.templateName = template.name;
            this.templateDescription = template.description || '';
            this.$store.commit(SET_TEMPLATE_DATASETS, dataSets);
        }
    }
};
__decorate([
    Inject()
], CreateTemplatePage.prototype, "organizationId", void 0);
__decorate([
    Validate({ required, maxLength: maxLength(TEXT_FIELD_MAX_LENGTH) })
], CreateTemplatePage.prototype, "templateName", void 0);
__decorate([
    Validate({ maxLength: maxLength(TEXT_FIELD_MAX_LENGTH) })
], CreateTemplatePage.prototype, "templateDescription", void 0);
CreateTemplatePage = __decorate([
    Component({
        components: {
            DatasetsEditor,
            DatasetsModal,
        },
    })
], CreateTemplatePage);
export default CreateTemplatePage;
