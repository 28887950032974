import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let DatasetsOverlaySteps = class DatasetsOverlaySteps extends Vue {
};
__decorate([
    Prop()
], DatasetsOverlaySteps.prototype, "overlaySteps", void 0);
DatasetsOverlaySteps = __decorate([
    Component({})
], DatasetsOverlaySteps);
export default DatasetsOverlaySteps;
